import { patchState, signalStoreFeature, withMethods } from "@ngrx/signals";
import { ApiService } from "../../api/services";
import { inject } from "@angular/core";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { exhaustMap, tap } from "rxjs";
import { FunctionsStore } from "../functions/functions.store";
import { setAllEntities } from "@ngrx/signals/entities";
import { EventTypesStore } from "../event-types/event-types.store";
import { SchemasStore } from "../schemas/schemas.store";


export function withScenarioConstructorDataMethods() {
    return signalStoreFeature(
        withMethods((
            store: any,
            apiService: ApiService = inject(ApiService),
            functionsStore = inject(FunctionsStore),
            eventTypesStore = inject(EventTypesStore),
            schemasStore = inject(SchemasStore),
        ) => ({
            load: rxMethod<void>(
                exhaustMap(() => {
                    return apiService.apiScenarioConstructorDataGet().pipe(
                        tap({
                            next: ({variables, functions, event_types, schemas}) => {
                                patchState(functionsStore, setAllEntities(functions));
                                patchState(functionsStore, { isLoaded: true });
                                patchState(eventTypesStore, setAllEntities(event_types));
                                patchState(eventTypesStore, { isLoaded: true });
                                patchState(store, setAllEntities(variables));
                                patchState(store, { isLoaded: true });
                                patchState(schemasStore, setAllEntities(schemas));
                                patchState(schemasStore, { isLoaded: true });
                            },
                            error: console.error,
                        })
                    );
                })
            ),
        })),
    )
}
