import { signalStore, withComputed, withState } from "@ngrx/signals";
import { withEntities } from "@ngrx/signals/entities";
import {  ScenarioSchema, ScenarioVariable } from "../../api/models";
import { withSchemasMethods } from "./schemas.methods";
import { computed } from "@angular/core";
import { SearchSelectGroup } from "../../ui/form/search-select/SearchSelectGroup.class";
import { LanguageService } from "../../translate/language.service";
import { SelectOption } from "../../ui/form/search-select/SelectOption.class";
import { initialSchemaState } from "./schemas.types";

export const SchemasStore = signalStore(
    { providedIn: 'root'},
    withState(initialSchemaState),
    withEntities<ScenarioSchema>(),
    withSchemasMethods(),
    withComputed((store) => ({
        getSelectedSearchSelectGroup: computed(() => {
            let webhookMaping: ScenarioSchema = store.entityMap()[store.selected_id()];
            return new SearchSelectGroup({
                id: store.group_id(),
                name: LanguageService.translate(`context.names.${store.group_id()}`),
                sort: 1,
                value: (webhookMaping.properties || []).map((value: ScenarioVariable) => {
                    return new SelectOption({
                        id: value.id,
                        label: LanguageService.translate(value.name),
                        value: '*'
                    })
                }) 
            })
        })
    })),
);